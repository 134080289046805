<template>
  <div>
    <NavigationBar titel="Abwesenheiten" :actions="actions"></NavigationBar>
    <TippCard
      hintID="Verfuegbarkeit_Abwesenheiten_1_V1"
      text="Für Zeiträume, in denen du nicht verfügbar bist, kannst du Abwesenheiten eintragen. Daraus kann eine Prognose der Organisationsstärke generiert werden und für die Zeit der Abwesenheit bspw. Rückmeldungen in Veranstaltungen automatisiert werden."
    >
    </TippCard>
    <section>
      <template>
        <v-container fluid>
          <v-row
            v-if="$store.state.organization.membership.availability.isOnLeave"
            justify="center"
            align="center"
          >
            <v-col cols="12">
              <active-leave-card
                :end="
                  $store.state.organization.membership.availability.activeLeave
                    .end.timestamp
                "
                :id="
                  $store.state.organization.membership.availability.activeLeave
                    .id
                "
              ></active-leave-card>
            </v-col>
          </v-row>
          <leaves-statistics-overview v-if="showAllLeaves">
          </leaves-statistics-overview>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-exit-run" title="Abwesenheiten">
                <template v-slot:actions>
                  <v-btn-toggle v-model="helpers.displayMode" dense mandatory>
                    <v-btn depressed outlined icon value="table">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                    <v-btn outlined icon value="calendar">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-calendar-blank</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div v-if="helpers.displayMode === 'table'" class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="table"
                ></v-skeleton-loader>
                <leaves-data-table v-else :leaves="leaves"></leaves-data-table>
              </div>
              <div v-else class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="calendar"
                ></v-skeleton-loader>
                <leaves-calendar v-else :leaves="leaves"></leaves-calendar>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import ActiveLeaveCard from "@/components/availability/leaves/ActiveLeaveCard.vue";
import LeavesDataTable from "@/components/availability/leaves/LeavesDataTable.vue";
import LeavesCalendar from "@/components/availability/leaves/LeavesCalendar.vue";
import LeavesStatisticsOverview from "@/components/availability/leaves/LeavesStatisticsOverview.vue";
import {
  AVAILABILITY_LEAVES_create,
  AVAILABILITY_LEAVES_read_all,
} from "@/data/permission-types.js";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_LEAVES } from "@/store/action-types.js";

export default {
  name: "availability-leaves",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
    ActiveLeaveCard,
    LeavesDataTable,
    LeavesCalendar,
    LeavesStatisticsOverview,
  },
  data() {
    return {
      search: "",
      actions: [
        {
          title: "Erstellen",
          permission: `${AVAILABILITY_LEAVES_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "availability-leaves-new" });
          },
        },
      ],
      helpers: {
        displayMode: "table",
      },
      leavesPersonFilter: "own",
      leavesStateFilter: "active",
    };
  },
  computed: {
    leaves() {
      const leaves = this.$store.state.availability.leaves;
      return leaves.map((leave) => {
        // status is active if duration.start.timestamp is now or in the past and duration.end.timestamp is in the future
        // status is upcoming if duration.start.timestamp is in the future
        // status is finished if duration.end.timestamp is in the past

        return {
          ...leave,
          status: this.getLeaveStatus(
            leave.duration.start.timestamp.toDate(),
            leave.duration.end.timestamp.toDate()
          ),
        };
      });
    },
    showAllLeaves() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_read_all}`
      );
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "leaves", data: this.leaves }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var allowedToReadAllLeaves = this.$store.getters[
        "organization/checkPermissionByID"
      ](`${AVAILABILITY_LEAVES_read_all}`);
      if (allowedToReadAllLeaves) {
        this.$store.dispatch(`${AVAILABILITY}${GET_LEAVES}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: false,
          },
        });
      } else {
        this.$store.dispatch(`${AVAILABILITY}${GET_LEAVES}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: true,
            onlyOwn: true,
          },
        });
      }
    },
    getLeaveStatus(start, end) {
      const now = new Date().getTime();

      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();

      if (startTime > now) {
        return "upcoming";
      } else if (startTime <= now && endTime >= now) {
        // Wenn Startzeit jetzt oder in Vergangenheit und Endzeit noch in der Zukunft liegt, dann "active"
        return "active";
      } else if (endTime < now) {
        // Wenn Startzeit und Endzeit in der Vergangenheit liegen, dann "finished"
        return "finished";
      } else {
        return "error";
      }
    },
  },
};
</script>

<style>
.transparent-background {
  background-color: transparent !important;
}
</style>
